/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import * as api from 'api/monthlyReport';
import MonthlyReportDomain from 'domain/master/storeManagement/monthlyReport';
import { useFormik } from 'formik';
import useToastNotification from 'hooks/useToastNotification';
import { MonthlyDailySalesManagementResult, MonthlyDailyBudgetPerformanceManagementResult } from 'types/api/sales';
import MonthlyDailyBudgetPerformanceManagementReportDomain from 'domain/master/storeManagement/MonthlyDailyBudgetPerformanceManagementReport';
import MonthlyDailySalesManagementReportDomain from 'domain/master/storeManagement/MonthlyDailySalesManagementReport';

const useMonthlyReport = (orgCode: string, monthlyStartDate: string,
  monthlyEndDate: string, monthlyComparePrevYear:number) => {
  const [salesMangementReport, setSalesMangementReport] = useState<MonthlyDailySalesManagementResult>({detailList: [], total: MonthlyDailySalesManagementReportDomain.generateInitial()});
  const [getMediaMst, setMediaMst] = useState();

  const [budgetPerfomanceManagementMonthlDailyReport, setBudgetPerfomanceManagementMonthlDailyReport] = useState<MonthlyDailyBudgetPerformanceManagementResult>({detailList: [], total: MonthlyDailyBudgetPerformanceManagementReportDomain.generateInitial()});
  const [getIsLoading, setIsLoading] = useState(true);
  const [discountSumary, setdiscountSumary] = useState<Array<any>>();
  const [listHeader, setListHeader] = useState<Array<any>>([]);
  const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
    undefined,
  );

  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const path = 'daySeparateSalesManagement';
    const pathBudget = 'daySeparateBudgetPerformanceManagement';
    setIsLoading(true);

    Promise.all([
      api.getMonthlyReportV6(companyCode, orgCode, monthlyStartDate, monthlyEndDate, path, monthlyComparePrevYear),
      api.getMediaMstV3(companyCode, orgCode, monthlyStartDate, monthlyEndDate, path),
      api.getDaySeparateBudgetPerfomanceManagementV4(companyCode, orgCode, monthlyStartDate, monthlyEndDate, pathBudget, monthlyComparePrevYear),
      api.getDiscountListHeader(companyCode, orgCode, monthlyStartDate, monthlyEndDate),
      api.getDaySeparateDiscountList(companyCode, orgCode, monthlyStartDate, monthlyEndDate),
    ]).then(([monthlyReportResponse, mediaMstResponse, budgetPerformanceResponse, discountHeaderReportResponse, discountSummaryResponse]) => {
      if (isSubscribed) {
        setSalesMangementReport({
          detailList: monthlyReportResponse.detailList.map((result: any) => new MonthlyDailySalesManagementReportDomain(result)),
          total: new MonthlyDailySalesManagementReportDomain(monthlyReportResponse.total)
        });
        setMediaMst(mediaMstResponse);
        setBudgetPerfomanceManagementMonthlDailyReport({
          detailList: budgetPerformanceResponse.detailList.map((result: any) => new MonthlyDailyBudgetPerformanceManagementReportDomain(result)),
          total     : new MonthlyDailyBudgetPerformanceManagementReportDomain(budgetPerformanceResponse.total)
        });
        setListHeader(discountHeaderReportResponse);
        setdiscountSumary(discountSummaryResponse);
      }
      setIsLoading(false);
    });

    // NOTE: クリーンアップ関数内でisSubscribedをfalseにすることでコンポーネントのアンマウント後の非同期タスクを無効化する（メモリリークを防ぐ）
    return () => { isSubscribed = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyComparePrevYear, monthlyEndDate, monthlyStartDate, orgCode]);

  return {
    getMediaMst, salesMangementReport, setSalesMangementReport, budgetPerfomanceManagementMonthlDailyReport, discountSumary, getIsLoading, setIsLoading, listHeader
  };
};

type ActionType = 'getFetchOption' | 'getFetchOptionforeCast' | 'getFetchOptionDiscountSumary';

const ActionType :{[key in ActionType]: ActionType} = {
  getFetchOption: 'getFetchOption',
  getFetchOptionforeCast: 'getFetchOptionforeCast',
  getFetchOptionDiscountSumary: 'getFetchOptionDiscountSumary',
};

export const useGetListMonthlyReport = () => {
  const onSubmit = () => {

  };

  const formik = useFormik({
    initialValues: MonthlyReportDomain.generateInitial(),
    onSubmit,
  });

  const [action, setAction] = useState<ActionType>(ActionType.getFetchOption);
  if (action === ActionType.getFetchOption) { }

  if (action === ActionType.getFetchOptionforeCast) {}

  if (action === ActionType.getFetchOptionDiscountSumary) {}

  return {
    action,
    setAction,
    ActionType,
    formik,
  };
};

export const useDownload = () => {
  const { errorNotification } = useToastNotification();
  const downloadMonthlyReportCsv = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportCsv(monthlyReport, '月次日別売上管理表.csv').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };

  const downloadBudgetPerfomanceManagementCSV = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportCsv(monthlyReport, '月次日別予実管理表.csv').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };

  const downloadMonthlyReportExcel = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportExcel(monthlyReport, '月次日別売上管理表.xlsx').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };

  const downloadBudgetPerfomanceManagementExcel = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportExcel(monthlyReport, '月次日別予実管理表.xlsx').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };
  const downloadSeparateDiscountReportCsv = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportCsv(monthlyReport, '月次日別値引・割引集計表.csv').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };
  const downloadSeparateDiscountExcel = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportExcel(monthlyReport, '月次日別値引・割引集計表.xlsx').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };


  return {
    downloadMonthlyReportCsv,
    downloadMonthlyReportExcel,
    downloadBudgetPerfomanceManagementCSV,
    downloadBudgetPerfomanceManagementExcel,
    downloadSeparateDiscountReportCsv,
    downloadSeparateDiscountExcel,
  };
};

export default useMonthlyReport;
