/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import * as api from 'api/monthlyReport';
import MonthlyReportDomain from 'domain/master/storeManagement/monthlyReport';
import { useFormik } from 'formik';
import useToastNotification from 'hooks/useToastNotification';
import MonthlyBudgetPerformanceManagementReportDomain from 'domain/master/storeManagement/MonthlyBudgetPerformanceManagementReport';
import MonthlyDiscountSummaryReportDomain from 'domain/master/storeManagement/monthlyDiscountSummaryReport';
import MonthlySalesManagementReportDomain from 'domain/master/storeManagement/monthlySalesManagementReport';

const useMonthlyReport = (
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate:string,
  categoryAry: any,
) => {
  const [monthlyReport, setMonthlyReport] = useState<Array<MonthlySalesManagementReportDomain>>([]);
  const [discountMonthlyReport, setDiscountmonthlyReport] = useState<MonthlyDiscountSummaryReportDomain>(MonthlyDiscountSummaryReportDomain.generateInitial());
  const [budgetPerfomanceManagementMonthlyReport, setBudgetPerfomanceManagementMonthlyReport] = useState<Array<MonthlyBudgetPerformanceManagementReportDomain>>([]);
  const [getIsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const path = 'salesmanagemet';
    const pathBudget = 'budgetPerformanceManagement';
    const pathMonthlyDiscount = 'separateDiscountList';

    setIsLoading(true);

    Promise.all([
      api.getMonthlyReportV3(
        companyCode,
        orgCode,
        monthlyStartDate,
        monthlyEndDate,
        path,
        categoryAry,
      ),
      api.getBudgetPerfomanceManagementV3(
        companyCode,
        orgCode,
        monthlyStartDate,
        monthlyEndDate,
        pathBudget,
        categoryAry,
      ),
      api.getDiscountmonthlyReportV4(
        companyCode,
        orgCode,
        monthlyStartDate,
        monthlyEndDate,
        pathMonthlyDiscount,
        categoryAry,
      ),
    ]).then(([monthlyReportResponse, budgetPerformanceResponse, discountMonthlyReportResponse]) => {
      if (isSubscribed) {
        setMonthlyReport(monthlyReportResponse.map((result: any) => new MonthlySalesManagementReportDomain(result)));
        setBudgetPerfomanceManagementMonthlyReport(budgetPerformanceResponse.map((result: any) => new MonthlyBudgetPerformanceManagementReportDomain(result)));
        setDiscountmonthlyReport(discountMonthlyReportResponse);
      }
      setIsLoading(false);
    });

    // NOTE: クリーンアップ関数内でisSubscribedをfalseにすることでコンポーネントのアンマウント後の非同期タスクを無効化する（メモリリークを防ぐ）
    return () => { isSubscribed = false; };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgCode, monthlyStartDate, monthlyEndDate, categoryAry]);


  return {
    monthlyReport, setMonthlyReport, budgetPerfomanceManagementMonthlyReport, getIsLoading, setIsLoading, discountMonthlyReport, setDiscountmonthlyReport
  };
};

type ActionType = 'getFetchOption' | 'getFetchOptionforeCast' | 'getFetchOptionDiscountSumary';

const ActionType :{[key in ActionType]: ActionType} = {
  getFetchOption: 'getFetchOption',
  getFetchOptionforeCast: 'getFetchOptionforeCast',
  getFetchOptionDiscountSumary: 'getFetchOptionDiscountSumary',
};


export const eventScroll = (e: { target: any; }) => {
  const { target } = e;

  const layout_sale_right_01 = document.getElementById('layout_sale_right_01');
  const layout_sale_right_02 = document.getElementById('layout_sale_right_02');
  const layout_sale_02_buttom_right_set_scroll = document.getElementById('layout_sale_02_buttom_right_set_scroll');
  if (layout_sale_right_01 && layout_sale_right_02 && layout_sale_02_buttom_right_set_scroll) {
    layout_sale_right_01.scrollLeft = target.scrollLeft;
    layout_sale_right_02.scrollLeft = target.scrollLeft;
    layout_sale_02_buttom_right_set_scroll.scrollLeft = target.scrollLeft;
  }
};
export const useGetListMonthlyReport = () => {
  const onSubmit = () => {

  };

  const formik = useFormik({
    initialValues: MonthlyReportDomain.generateInitial(),
    onSubmit,
  });

  const [action, setAction] = useState<ActionType>(ActionType.getFetchOption);

  return {
    action,
    setAction,
    ActionType,
    formik,
  };
};

export const useDownload = () => {
  const { errorNotification } = useToastNotification();
  const downloadMonthlyReportCsv = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportCsv(monthlyReport, '月次売上管理表.csv').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };

  const downloadBudgetPerfomanceManagementCSV = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportCsv(monthlyReport, '月次予実管理表.csv').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };

  const downloadMonthlyReportExcel = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportExcel(monthlyReport, '月次売上管理表.xlsx').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };

  const downloadBudgetPerfomanceManagementExcel = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportExcel(monthlyReport, '月次予実管理表.xlsx').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };
  const downloadDiscountReportCsv = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportCsv(monthlyReport, '月次値引・割引集計表.csv').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };

  const downloadDiscountReportExcel = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportExcel(monthlyReport, '月次値引・割引集計表.xlsx').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };

  return {
    downloadMonthlyReportCsv,
    downloadMonthlyReportExcel,
    downloadBudgetPerfomanceManagementCSV,
    downloadBudgetPerfomanceManagementExcel,
    downloadDiscountReportCsv,
    downloadDiscountReportExcel,
  };
};

export default useMonthlyReport;
